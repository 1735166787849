<template>
  <div class="noroot" style="background:#f9f9f9">
    <div style="height:100px;padding-top:60px;display: flex;flex-direction:row;justify-content:center;align-items:center">
        <div style="margin-right:240px">
          <router-link to="/" class="home" style="display: flex;flex-direction:row;">
            <img :src="require('../assets/logo.jpg')" class="logo" style="width:50px"/>
            <span  style="color: #000;">
              <h3>中医临床药学服务<br />与调剂教育平台</h3>
            </span>
          </router-link>
        </div>
        <div style="width:240px;height:40px;text-align:right;line-height:40px;">
          <router-link to="/" style="color:#FF9552;cursor:pointer">返回首页</router-link>&nbsp;&nbsp;|&nbsp;&nbsp;<a @click="quit" style="color:#FF9552;cursor:pointer">退出</a>
        </div>
    </div>
      <div class="wrap" style="position: relative;">
      <!-- 找回密码 -->
        <div class="modal" >
          <div class="title">账号安全</div>
          <div class="telModal" >
            <span class="modal-title">
              <p>修改手机号</p>
            </span>
            <div style="position: relative;">

              <div class="tel-err" v-if="telErr">
                <!-- <img :src="telError" class="telErr" /> -->
                {{telNumErr ?' 手机号不正确' :'手机号格式错误'}}
              </div>
              <div class="tel-inputTel">
                <div class="tel-tel">
                  <!-- <img :src="iphone" class="iphone" /> -->
                  <input id="iPhone"
                    @blur="userBlur"
                    type="text"
                    placeholder="原手机号"
                    v-model="oldUserTel"
                    autocomplete="off"
                    maxlength="11"
                  />
                </div>
              </div>
            </div>
            <div style="position:relative" class="tel-inputTel">
              <div class="tel-tip">
                <input
                  type="text"
                  placeholder="+86"
                  disabled
                  autocomplete="off"
                />
              </div>
              <div style="border: 2px solid #eee;width: 205px;line-height: 36px;height: 36px;margin-bottom: 40px;">
                <!-- <img :src="iphone" class="iphone" /> -->
                <input
                  id="newPhone"
                  style="padding-left:5px"
                  @blur="onBlur"
                  type="text"
                  placeholder="新手机号"
                  v-model="userTel"
                  autocomplete="off"
                  maxlength="11"
                />
              </div>
              <div class="tel-err2" v-if="newTelErr">
                <!-- <img :src="telError" class="telErr" /> -->
                {{errMsg }}
              </div>
            </div>
            <!-- <div class="codeErr" v-if="resErr" style="color: #2c2c2c; width: 250px;top:47%;left:38.4%">该手机号未注册，你可以
                <router-link to="./register" style="color: #FF9552" >去注册</router-link>
            </div> -->
            <!-- 滑块 -->
            <div id="drag">
              <div class="drag_bg"></div>
              <div
                class="drag_text"
                onselectstart="return false;"
                unselectable="on"
              >
                请拖动滑块，拖到最右边
              </div>
              <div class="handler handler_bg"></div>
            </div>
            <!-- 获取验证码 -->
            <div class="verity" v-if="dragStatus &&  !telErr">
              <div class="verity-num">
                <input
                  type="text"
                  placeholder="请输入验证码"
                  v-model="validCode"
                  autocomplete="off"
                  required
                />
              </div>
              <div>
                <button
                  @click="getCode"
                  :disabled="disabled"
                  class="verity-btn"
                >
                  {{ text }}
                </button>
              </div>
            </div>
            <div class="codeErr" v-if="codeErr">{{codeErrMsg}}</div>
            <button @click="changePhone" type="submit" :style="`background:${btnStatus ? '#ddd': '#FF9552'};letter-spacing: 2px;margin-bottom:80px`" :disabled="btnStatus">确定</button>
          </div>
          <!-- Warning -->
          <div v-if="warningMsg" class="div-warn">
            <div class="warning">
              <span>{{ msg }}</span>
            </div>
          </div>
        </div>
   
      </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { post } from "@/utils/common";
import { useRoute, useRouter } from "vue-router";


export default {
  name: "Login",
  components: {},
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      logo: require("../assets/logo3.png"),
      tel: require("../assets/tel.png"),
      user2: require("../assets/user2.png"),
      telError: require("../assets/telErr.png"),
      codeErrMsg:'',
      userTel: "",
      oldUserTel:"",
      warningMsg: false,
      warning: false,
      newTelErr:false,
      errMsg:"",
      telErr: false,
      telNumErr:false,
      btnStatus:true,
      validCode: "",
      codeErr: false,
      dragStatus: false,
      msg: "", 
      text: "发送验证码",
      disabled: false,
      phoneNumber:''
    });

    onMounted(() => {
      // const { phoneNumber } = route.params || {};
      // console.log(phoneNumber);
      // state.oldUserTel = phoneNumber;
      
      const { phoneNumber } = route.params || {};
      state.phoneNumber = phoneNumber
      drag(); 
    });

    //获取验证码
    const getCode = () => {
      state.newTelErr = false;
      post("userInformation/getMessageCode", { userTel: state.userTel }, (_, res) => {
        state.disabled = true;
        let num = 60;
        state.text = `${num}s后重新获取`;
        let timer = setInterval(() => {
          num--;
          if (num > -1) {
            state.text = `${num}s后重新获取`;
          } else {
            clearInterval(timer);
            state.disabled = false;
            state.text = "获取验证码";
          }
        }, 1000);
        if(state.dragStatus  && !state.telErr) {
                state.btnStatus=false;
            }
      },err=>{
      //  state.errMsg = err;
      //  state.newTelErr = true;
       NotifyModal(err)
      });
    };


    const NotifyModal = (msg) =>{
      state.warningMsg = true;
      state.msg = msg;
      setTimeout(function () {
        state.warningMsg = false;
      }, 2000);
    }

    const onBlur = () =>{
      
      let str = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      
      // if (state.userTel.length>10){
      if (str.test(state.userTel) == false) {
        state.newTelErr = true;
        state.errMsg = '手机号格式错误';
      } else {
        state.newTelErr = false;
        state.errMsg = '';
      }
      // if(state.oldUserTel === state.userTel){
      //   NotifyModal('新旧手机号重复，请重输')
      // }
      // post("checkRegister", { username: state.userTel }, (_, res) => {
      //   if (res.body.feedbackMsg == false) {
      //     // state.resErr = true;
      //     state.btnStatus =true;
      //   } else {
      //     // state.resErr = false;
      //     // state.btnStatus =false;
      //   }
          
      // });
      return;
    }

    // 判断手机号格式
    const userBlur = () => {
      // if(state.oldUserTel !== phoneNumber){
        //     state.telErr = true;
      //     state.telNumErr = true;
      //     // return;
      //     console.log('不一样');
      // }else{
        //    console.log('一样');
      //   state.telErr = false;
      //   state.telNumErr = false
      // }
      let str = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (str.test(state.oldUserTel) == false) {
        state.telErr = true;
        state.btnStatus =true;
      } else if(state.oldUserTel !== state.phoneNumber){
        state.telErr = true;
        state.telNumErr = true;
      } else {
        state.telErr = false;
        state.telNumErr = false;
        // state.btnStatus = false;

      
      }
        //旧手机是否正确（即是否是当前用户的手机号）
        
    };
    

    // 修改密码
    const changePhone = () => {
      
      // 新手机号和验证码非空判断
    //   console.log("判断", !!state.validCode.trim());
     
      if(!state.validCode.length){
          state.codeErr = true;
          state.codeErrMsg = '验证码为空！'
        return;
      }
      post(
        "userInformation/userChangeBinding",
        {
          oldUserTel: state.oldUserTel,
          userTel: state.userTel,
          validCode: state.validCode,
        },
        (_, res) => {
          //验证码是否正确
          NotifyModal(res.body.feedbackMsg);
          setTimeout(()=>{
            router.push({
            path: "/my"
          });
          },2000)
          
          // if (res.body.errorMsg) {
          //   state.codeErr = true;
          //   return;
          // } else {
          //   state.codeErr = false;
          // }
          // initLogindata(res.body.token.body);
          // console.log("dadadada", res.body.token.body);
          
        //   state.second = true;
        },(err)=>{
          console.log(err);
          if(err){
            state.codeErr = true;
            state.codeErrMsg = err
          }
        }
      );
    };

    // 滑块
    const drag = () => {
    //   console.log("telPagetelPagetelPage222");
    //   if (!state.telPage) return;
    //   console.log("telPagetelPagetelPage555");
      var x,
        dragdom = document.getElementById("drag"),
        isMove = false;
      var handler = dragdom.getElementsByClassName("handler")[0];
      var drag_bg = dragdom.getElementsByClassName("drag_bg")[0];
      var text = dragdom.getElementsByClassName("drag_text")[0];
      var maxWidth = (dragdom.clientWidth || 0) - (handler.clientWidth || 0); //能滑动的最大间距

      //鼠标按下时候的x轴的位置
      handler.addEventListener("mousedown", function (e) {
        console.log("mousedown");
        if (text.innerHTML === "验证通过") return;
        isMove = true;
        x = e.pageX - parseInt(handler.style.left || 0, 10);
      });

      //鼠标指针在上下文移动时，移动距离大于0小于最大间距，滑块x轴位置等于鼠标移动距离
      document.addEventListener("mousemove", function (e) {
        if (isMove) {
          var _x = e.pageX - x;
          if (_x > 0 && _x <= maxWidth) {
            handler.style.left = `${_x}px`;
            drag_bg.style.width = `${_x}px`;
          } else if (_x > maxWidth) {
            //鼠标指针移动距离达到最大时清空事件
             handler.style.left = `${maxWidth}px`;//当鼠标加速时把移动条置满
            drag_bg.style.width = `${maxWidth}px`;
            state.dragStatus = true;
            dragOk();
            // if()
          }
        }
      });
      document.addEventListener("mouseup", function (e) {
        if (isMove) {
          isMove = false;
          var _x = e.pageX - x;
          if (_x < maxWidth) {
            //鼠标松开时，如果没有达到最大距离位置，滑块就返回初始位置
            handler.style.left = `0px`;
            drag_bg.style.width = `0px`;
          } else {
          }
        }
      });

      //清空事件
      function dragOk() {
        isMove = false;
        handler.classList.remove("handler_bg");
        handler.classList.add("handler_ok_bg");
        text.innerHTML = "验证通过";
        dragdom.style.color = `#fff`;
        handler.removeEventListener("mousedown", drag, false);
        document.removeEventListener("mousemove", drag, false);
        document.removeEventListener("mouseup", drag, false);
      }
    };

   
    const quit = () =>{
       router.push({
          // 跳到登录页并且清除缓存
          path: "/my",
          query:{
            myIndex:3
          }
        });
    }
    //设置密码
    
    return {
      ...toRefs(state),
      drag,
      getCode,
      changePhone,
      userBlur,
      onBlur,
      quit
    };
  },
};
</script>


<style lang="less" scoped>
//空白背景
.noroot {
  height: 960px;
  position: relative;
}
.title {
    width: 700px;
    background: #FF9552;
    height: 70px;
    color: #fff;
    text-align: center;
    line-height: 70px;
    font-size: 20px;
    letter-spacing: 2px;
}

.modal {
  width: 700px;
  height: auto;
//   position: absolute;
  background: #fff;
//   right: 0px;
//   top: 0px;
margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-title {
  margin: 5%;
  text-align: center;
  font-size: 20px;
}

.input-info,
button,
.tel-inputTel,
#drag,
.verity,.pwd{
  border-radius: 4px;
  margin-left: 208px;
  font-size: 14px;
}

.input-info ,.pwd{
  border: 2px solid #eee;
  width: 280px;
  height: 36px;
  margin-bottom: 40px;
}

.input-info > input {
  // border: 2px solid #ddd;
  margin-left: 2%;
  height: 36px;
  width: 240px;
}

button {
  width: 284px;
  height: 36px;
  color: #fff;
  background: #FF9552;
}

.user {
  width: 18px;
  height: 18px;
  margin: 5px;
  opacity: 0.7;
}


.forget-pwd {
  color: grey;
  font-size: 14px;
  display: block;
  margin: 15px 0px 15px 320px;
}


.tel,
.user2 {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-left: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 18px;
  position: relative;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  background: #FF9552;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 4px;
}

router-link:hover {
  color: #FF9552;
}

// 手机号登录
.iphone {
  width: 25px;
  margin: 0 5px;
}
.tel-inputTel {
  display: flex;
}
.tel-tip,
.tel-tel,
.verity-num {
  border: 2px solid #eee;
  // width: 300px;
  height: 36px;
  margin-bottom: 40px;
  > input {
    margin-left: 5px;
    height: 36px;
  }
}
.tel-tip {
  width: 60px;
  margin-right: 10px;
  > input {
    margin: 0;
    width: 60px;
  }
}
.tel-tel {
  width: 280px;
  > input {
    width: 240px;
  }
}
//滑块
#drag {
  position: relative;
  background: #e8e8e8;
  width: 284px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 40px;
}
#drag .handler {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 37px;
  border: 1px solid #ccc;
  cursor: move;
}

.handler_bg {
  background: #fff url("../assets/dragR.png") no-repeat;
  background-size: 25px;
  background-position: 50% 50%;
}

.handler_ok_bg {
  background: #fff url("../assets/drag1.png") no-repeat;
  background-size: 30px;
  background-position: 50% 50%;
}

#drag .drag_bg {
  background: #7ac23c;
  height: 38px;
  width: 0px;
}

#drag .drag_text {
  position: absolute;
  top: 0px;
  width: 284px;
  user-select: none;
}
//验证码
.verity {
  display: flex;
  margin-bottom: 40px;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
}
.verity-btn {
  width: 100px;
  margin-left: 10px;
  height: 36px;
  background: #FF9552;
  cursor: pointer;
}
.verity-num {
  margin-bottom: 0px;
  width: 170px;
  > input {
    width: 160px;
  }
}
// .verity-img {
//   // border: #ccc 1px solid;
//   width: 34px;
//   height: 34px;
//   margin-left: 8px;
//   // background-image: url("../assets/verity1.png");
//   background-size: 30px;
//   background-position: 50% 50%;
// }
//warnijng
.div-warn {
  position: absolute;
  width: 280px;
  height: 4vh;
  top: 45%;
  left: 37%;
  color: #000;
  font-size: 14px;
  background: #FFF7EA;
  z-index: 100;
  border-radius: 4px;
  text-align: center;
  padding: 4vh 1vw;
}
//telErr
.tel-err {
  position: absolute;
  color:red;
  left: 208px;
  font-size: 13px;
  top: 49px;
  
}
.tel-err2 {
  position: absolute;
  color:red;
  left: 2px;
  font-size: 13px;
  top: 50px;
  
}
.telErr {
  width: 20px;
  margin: 0 5px;
}
//codeErr
.codeErr {
  font-size: 14px;
  color: red;
  width: 100%;
  height: 16px;
  position: absolute;
  top: 75%;
  left: 38.4%;
}

</style>